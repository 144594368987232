import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { first, startWith, take } from "rxjs/operators";
import { CommonFunctionService } from "src/app/services/commonfunction.service";
import { WebService } from "src/app/services/Webservice.service";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  submitted: boolean;
  loginvalid:boolean;
  hide = true;
  Role_id: any;
  user_data: any = []
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private service: WebService,
    public CommonService: CommonFunctionService,
  ) { }
  ngOnInit(): void {
    this.loginform = this.formbuilder.group({
      emial: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });


  }

  onSubmit() {
    this.submitted = true;
    if (this.loginform.invalid) {
      return;
    } else {
      this.loginvalid = true;
      this.service.Token().then(r => {
        if (r) {
          localStorage.setItem('Prinsezeetoken', r);

          const body = [
            {
              "columnName": "user_name",
              "columnValue": this.loginform.get("emial").value
            },
            {
              "columnName": "user_password",
              "columnValue": this.loginform.get("password").value
            }];
          this.service.Ezee_user_list(body).then((s: any) => {
            if (s) {
              this.user_data = [{
                Role_id: s[0].role_id,
                Role_name: s[0].user_fullname
              }]
              localStorage.setItem("Role_id", JSON.stringify(this.user_data));
              localStorage.setItem("User_details", JSON.stringify(s));
              this.CommonService.SwalSuccess("Login Successfully", "Success");
              this.loginvalid = false;
              s[0].role_id===101?this.router.navigateByUrl("home"):this.CommonService.showError("Invalid Password", "Error");
              // s[0].role_id===101?this.router.navigateByUrl("home"):s[0].role_id===102?this.router.navigateByUrl("qcdashboard"):s[0].role_id===103?this.router.navigateByUrl("inspection"): this.CommonService.showError("Invalid Password", "Error");
            }
            else{
              this.loginvalid = false;
              this.submitted=false;
              this.CommonService.SwalError("Invalid Login Details", "Error");
              this.loginform.patchValue({
                emial:'',
                password:''
              })
            }
          })
        }
        else {
          this.loginvalid = false;
        }
      })
    }
  }


  // ===============================================================================================
}
