import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './services/AuthGard';
import { RoleGuard } from './services/RoleGard';
export const Approutes: Routes = [

  {
    path: '',
    component: FullComponent,
    // canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./pages/dashboards/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'manageuser',
        loadChildren: () => import('./pages/Usermanagement/manageclientuser/manageclientuser.module').then(m => m.ManageclientuserModule)
      },
      {
        path: 'agentsperformance',
        loadChildren: () => import('./pages/agentsperformance/agentsperformance.module').then(m => m.AgentsperformanceModule)
      },
      {
        path: 'downloadinvoice',
        loadChildren: () => import('./pages/downloadinvoice/downloadinvoice.module').then(m => m.DownloadinvoiceModule)
      },
      {
        path: 'performanceinvoice',
        loadChildren: () => import('./pages/performanceinvoice/performanceinvoice.module').then(m => m.PerformanceinvoiceModule)
      },
      {
        path: 'taxinvoice',
        loadChildren: () => import('./pages/taxinvoice/taxinvoice.module').then(m => m.TaxinvoiceModule)
      },
      {
        path: 'sendapklink',
        loadChildren: () => import('./pages/sendapklink/sendapklink.module').then(m => m.SendapklinkModule)
      },
      {
        path: 'managefielduser',
        loadChildren: () => import('./pages/Usermanagement/managefielduser/managefielduser.module').then(m => m.ManagefielduserModule)
      },

      {
        path: 'manageclients',
        loadChildren: () => import('./pages/Clients/manageclients/manageclients.module').then(m => m.ManageclientsModule)
      },
      {
        path: 'manageqcuser',
        loadChildren: () => import('./pages/Usermanagement/manageqcuser/manageqcuser.module').then(m => m.ManageqcuserModule)
      },
      {
        path: 'managebranches',
        loadChildren: () => import('./pages/Clients/managebranches/managebranches.module').then(m => m.ManagebranchesModule)
      },
      {
        path:'managejoblist',
        loadChildren:()=>import('./pages/Clients/joblist/joblist.module').then(m=>m.JoblistModule)
      },
      {
        path:'managejobtype',
        loadChildren:()=>import('./pages/Managejobtypes/jobtype/jobtype.module').then(m=>m.JobtypeModule)
      },
      {
        path:'managecretria',
        loadChildren:()=>import('./pages/Managejobtypes/cretria/cretria.module').then(m=>m.CretriaModule)
      },
      {
        path:'managedamages',
      loadChildren:()=>import('./pages/Managejobtypes/damages/damages.module').then(m=>m.DamagesModule)
      },
      {
        path:'manageproducts',
        loadChildren:()=>import('./pages/Managejobtypes/products/products.module').then(m=>m.ProductsModule)
      },
      // {
      //   path:'inspectionreport',
      //   loadChildren:()=>import('./pages/inspection-report/inspection-report.module').then(m=>m.InspectionReportModule)
      // }

    ]
  },

  { path: '404', loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule) },
  { path: '**', redirectTo: '/404' }
];
